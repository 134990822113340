import React from 'react'
import { LearnMoreCard } from '../learn-more-card'
import './styles.css';

export const LearnMore = ({ images, copy }) => {
  const staffing = {
    title: 'STAFFING',
    details: copy[1]
  }

  const freightHandling = {
    title: 'FREIGHT HANDLING',
    details: copy[2]
  }

  const valueAddedServices = {
    title: 'VALUE ADDED SERVICES',
    details: copy[3]
  }

  return (
    <div className="services-learn">
      <p>
        {copy[0]}
      </p>

      <div className="learn-more-card-list">
        <LearnMoreCard color='0177ae' service={staffing} image={images[0]} scrollTo="staffing" />
        <LearnMoreCard color='334857' service={freightHandling} image={images[1]} scrollTo="freight" />
        <LearnMoreCard color='e18533' service={valueAddedServices} image={images[2]} scrollTo="vas" />
      </div>
    </div>
  )
}
