import React from 'react';
import Link from 'gatsby-link';
import ScrollableAnchor from 'react-scrollable-anchor'
import chat from '../../../../assets/images/chat.png';
import genLabor from '../../../../assets/images/general_labor.jpg';
import skilled from '../../../../assets/images/skilled.jpg';
import clerical from '../../../../assets/images/clerical.jpg';
import manage from '../../../../assets/images/manage.jpg';
import onSite from '../../../../assets/images/onsite.jpg';
import {StaffingCard} from './staffing-card';
import './styles.css';

export const Staffing = ({ images, copy }) => (
  <div>
    <ScrollableAnchor id={'staffing'}><div/></ScrollableAnchor>
    <img className="mobile-staffing-img" src={images[3]} />
    <div className="staffing-info">
      <h1 style={{ letterSpacing: '0.1em', fontFamily: 'FuturaBold' }}>STAFFING</h1>

      <p style={{whiteSpace: 'pre-line'}}>
        {copy}
      </p>

      <h2 style={{marginTop: '40px', marginBottom: '20px', fontFamily: 'FuturaHeavy', letterSpacing: '0.08em'}}>STAFFING MODELS</h2>

      <img className="staffing-models" src={images[0]} style={{width: '100%', height: 'auto'}} />
      <img className="staffing-models-mobile" src={images[1]} style={{width: '90%', height: 'auto'}} />
    </div>

    <div className="provided-mobile">
      <h1 style={{fontFamily: 'FuturaHeavy', margin: 0, padding: '50px 0', color: '#0177ae'}}>WE PROVIDE THE FOLLOWING AND MORE</h1>

      <img src={images[2]} style={{ width: '100%', height: 'auto' }} />
    </div>

    <div className="provided">
      <h1 style={{
        width: '80%',
        textAlign: 'center',
        borderBottom: '1px solid black',
        lineHeight: '0.1em',
        margin: '50px auto',
        letterSpacing: '0.1em',
      }}>
        <span style={{background: '#fff', padding: '0 10px', fontFamily: 'FuturaHeavy'}}>WE PROVIDE THE FOLLOWING AND MORE</span>
      </h1>

      <div style={{
        width: '80%',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between'
      }}>
        <StaffingCard title="GENERAL LABOR" types={['Pickers', 'Packers']} image={genLabor} />
        <StaffingCard title="SKILLED WORKERS" types={['Forklift (all types)', 'Machine Operator', 'Maintenance Mechanic', 'Welders']} image={skilled} />
        <StaffingCard title="CLERICAL" types={['Customer Service Representative', 'Administrative Assistant', 'Receptionist', 'Accounts Receivable Clerk', 'Accounts Payable Clerk']} image={clerical} />
      </div>

      <div style={{
        width: '80%',
        display: 'flex',
        flexFlow: 'row',
        marginTop: '30px',
        justifyContent: 'space-between'
      }}>
        <StaffingCard title="MANAGEMENT" types={['Department Manager', 'Department Supervisor', 'Operations Supervisor', 'Operations Manager', 'General Manager', 'Plant Manager']} image={manage} />
        <StaffingCard title="ON-SITE SUPERVISION" types={['Customer Service Representative', 'Administrative Assistant', 'Receptionist', 'Accounts Receivable Clerk', 'Accounts Payable Clerk']} image={onSite} />
        
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          color: 'white',
          width: '32%',
          minWidth: '330px',
          fontFamily: 'sans-serif'
        }}>
          <div
            style={{
              backgroundColor: `#0177ae`,
              padding: '30px',
              paddingBottom: '50px',
              height: '100%'
            }}
          >
            <img src={chat} style={{width: '50%', height: 'auto'}}/>
            <h3 style={{letterSpacing: '0.1em', fontFamily: 'FuturaBold'}}>DON’T SEE WHAT YOU NEED LISTED?</h3>
            <p style={{marginBottom: '150px', fontFamily: 'FuturaBook'}}>
            We can tailor the service needed for you.
            </p>

            <Link to='/contact' style={{textDecoration: 'none'}}>
              <div className="staffing-contact">
                CONTACT US
                <div
                  style={{
                    width: 0,
                    height: 0,
                    marginLeft: '10px',
                    borderTop: '6px solid transparent',
                    borderLeft: '9px solid #334857',
                    borderBottom: '6px solid transparent'}}>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);