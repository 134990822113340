import React from 'react';
import './styles.css';

export const StaffingCard = ({title, types, image}) => (
  <div className="staffing-card">
    <img src={image} style={{ margin: 0 }} />
    <div
      style={{
        backgroundColor: `#0177ae`,
        padding: '30px',
        paddingBottom: '50px',
        height: '100%'
      }}
    >

      <h3 style={{letterSpacing: '0.1em', fontFamily: 'FuturaBold'}}>{title}</h3>
      
      <ul style={{fontFamily: 'FuturaBook'}}>
        {types.map(type => <li><span style={{position: 'relative',left: '-5px'}}>{type}</span></li>)}
      </ul>
    </div>
  </div>
);