import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import './styles.css';

export const ValueAddedServices = ({ images, copy }) => (
  <div>
    <div className="vas-container" style={{ backgroundImage: `url(${images[0]})` }}>
      <ScrollableAnchor id={'vas'}><div/></ScrollableAnchor>

      <h1 style={{ letterSpacing: '0.1em', fontFamily: 'FuturaBold' }}>VALUE ADDED SERVICES</h1>

      <p className="vas-info">
        {copy}
      </p>

      <img src={images[2]} className="vas-tech-mobile" />
    </div>

    <div className="vas-tech">
      <img src={images[1]} />
    </div>
  </div>
);