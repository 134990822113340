import React from 'react';
import './styles.css';

export const LearnMoreCard = ({ service, color, image, scrollTo }) => (
  <div className="learn-more-card-container" style={{ backgroundColor: `#${color}` }}> 
    <img className="learn-more-image" src={image} />
    <div className="learn-more-info">

      <h3 className="learn-more-title">{service.title}</h3>
      <p className="learn-more-details">
        {service.details}
      </p>
    </div>
    
    <div className="learn-more-anchor">
      <div style={{
          width: '170px',
          height: '30px',
          padding: '8px',
          fontWeight: 'bold',
          outline: 'none',
          cursor: 'pointer',
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'center',
        }}>
          <a style={{textDecoration: 'none', color: 'inherit'}} href={`#${scrollTo}`}>LEARN MORE</a>
          <div
            style={{
              width: 0,
              height: 0,
              marginLeft: '10px',
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderTop: '13px solid white'}}>
          </div>
        </div>
      </div>
  </div>
)
