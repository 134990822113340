import React from 'react';
import './styles.css';

export const ServicesHeader = ({ imgSrc, copy }) => (
  <div className="services-header" style={{ backgroundImage: `url(${imgSrc})` }}>
    <h1 className="services-h1">
      {copy}
    </h1>
  </div>
)
