import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import divider from '../../../../assets/images/vas_divider.png';
import './styles.css';

export const FrieghtHandling = ({ images, copy }) => (
  <div>
    <div className="freight-container">
      <ScrollableAnchor id={'freight'}><div/></ScrollableAnchor>
      <h1 style={{ letterSpacing: '0.1em', fontFamily: 'FuturaBold' }}>FREIGHT HANDLING</h1>

      <p style={{whiteSpace: 'pre-line'}}>
        {copy}
      </p>

      <img className="freight-models" src={images[0]} />
      <img className="freight-models-mobile" src={images[1]} />
    </div>

    <img src={divider} className="divider" />
  </div>
);