import React from "react"
import Layout from "../layouts"
import { ServicesHeader } from "../components/services-page/services-header"
import { LearnMore } from "../components/services-page/learn-more"
import { Staffing } from "../components/services-page/services/staffing"
import { FrieghtHandling } from "../components/services-page/services/freight-handling"
import { ValueAddedServices } from "../components/services-page/services/value-added-services"

const ServicesPage = ({ data }) => {
  const content = data.allContentfulServices.edges[0].node

  return (
    <Layout>
      <ServicesHeader
        imgSrc={content.headerImage.file.url}
        copy={content.headerCopy}
      />

      <div
        style={{
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
        }}
      >
        <LearnMore
          images={[
            content.staffingIntroImage.file.url,
            content.freightIntroImage.file.url,
            content.vasIntroImage.file.url,
          ]}
          copy={[
            content.servicesIntro.servicesIntro,
            content.staffingIntro.staffingIntro,
            content.freightIntro.freightIntro,
            content.vasIntro.vasIntro,
          ]}
        />
        <Staffing
          images={[
            content.staffingModels.file.url,
            content.staffingModelsMobile.file.url,
            content.weProvideMobile.file.url,
            content.staffingMainMobile.file.url,
          ]}
          copy={content.staffingMain.staffingMain}
        />
        <FrieghtHandling
          images={[
            content.freightModels.file.url,
            content.freightModelsMobile.file.url,
          ]}
          copy={content.freightMain.freightMain}
        />
        <ValueAddedServices
          images={[
            content.vasMainHeaderImage.file.url,
            content.vasMainImage.file.url,
            content.vasMainImageMobile.file.url,
          ]}
          copy={content.vasMain.vasMain}
        />
      </div>
    </Layout>
  )
}

export default ServicesPage

export const pageQuery = graphql`
  query servicesContent {
    allContentfulServices {
      edges {
        node {
          headerCopy
          servicesIntro {
            servicesIntro
          }
          staffingIntro {
            staffingIntro
          }
          freightIntro {
            freightIntro
          }
          vasIntro {
            vasIntro
          }
          staffingMain {
            staffingMain
          }
          freightMain {
            freightMain
          }
          vasMain {
            vasMain
          }

          headerImage {
            file {
              url
            }
          }
          staffingIntroImage {
            file {
              url
            }
          }
          freightIntroImage {
            file {
              url
            }
          }
          vasIntroImage {
            file {
              url
            }
          }
          staffingMainMobile {
            file {
              url
            }
          }
          staffingModels {
            file {
              url
            }
          }
          staffingModelsMobile {
            file {
              url
            }
          }
          weProvideMobile {
            file {
              url
            }
          }
          freightModels {
            file {
              url
            }
          }
          freightModelsMobile {
            file {
              url
            }
          }
          vasMainHeaderImage {
            file {
              url
            }
          }
          vasMainImage {
            file {
              url
            }
          }
          vasMainImageMobile {
            file {
              url
            }
          }
        }
      }
    }
  }
`
